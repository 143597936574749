exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aziende-tsx": () => import("./../../../src/pages/aziende.tsx" /* webpackChunkName: "component---src-pages-aziende-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manifesto-tsx": () => import("./../../../src/pages/manifesto.tsx" /* webpackChunkName: "component---src-pages-manifesto-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-scuole-tsx": () => import("./../../../src/pages/scuole.tsx" /* webpackChunkName: "component---src-pages-scuole-tsx" */),
  "component---src-pages-sociale-tsx": () => import("./../../../src/pages/sociale.tsx" /* webpackChunkName: "component---src-pages-sociale-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

